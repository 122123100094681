@use '../util/' as *;

.cards{
  display: grid;
  grid-template-columns: 1fr;
  gap: rem(30);

  @include breakpoint(medium){
    // STYLES FOR MEDIUM SCREENS
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(large){
    // STYLES FOR LARGE SCREENS
    grid-template-columns: repeat(4, 1fr);
  }

}

.card{
  position: relative;
  overflow: hidden;
  background: var(--card-bg);
  color: var(--dark-text1);
  //height: rem(255);
  text-align: center;
  padding: rem(25);
  border-radius: rem(5);
  text-align: center;
  transition: background 150ms ease-in-out;
  cursor: pointer;

  &:hover{
    background: var(--card-hover);
    top: rem(-5);
    
  }

  &--facebook{
    border-top: rem(5) solid var(--facebook);
  }

  &--twitter{
    border-top: rem(5) solid var(--twitter);
  }

  &--instagram{
    padding-top: rem(30);
    &::before{
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: rem(5);
      background: linear-gradient(255deg, hsl(329, 70%, 58%)0%, hsl(5, 77%, 71%)50.91%, hsl(37, 97%, 70%)100%)
    }
  }

  &--youtube{
    border-top: rem(5) solid var(--youtube);
  }

  &__platform{
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(20);
    margin-bottom: rem(28);
    margin-top: rem(5);
  }

  &__subtitle{
    font-size: rem(14);
    font-weight: 700;
    color: var(--text-color2);
  }

  &__icon{

    margin-right: rem(8);
    &--facebook{
    }

    &--twitter{

    }

    &--instagram{
    }

    &--youtube{
    }
  }

  &__username{
    font-size: rem(12);
    font-weight: 700;
    color: var(--text-color2);

  }

  &__followers{
    margin-bottom: rem(25);
  }

  &__count {
    color: var(--text-color);
    font-weight: 700;
    letter-spacing: rem(-2);
    line-height: 1;
    margin-bottom: rem(4);
  

    &--big{
      font-size: rem(56);
      font-weight: 700;
    }

    &--small{
      font-size: rem(32);
    }
  }

  &__label{
    font-size: rem(12);
    letter-spacing: rem(5);
    font-weight: 400;
    color: var(--text-color2);
    text-transform: uppercase;
    margin-top: rem(9);
  }

  &__change{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(12);
    font-weight: 700;

    &--up{
      color: var(--limegreen);
    }

    &--down{
      color: var(--brightred);
    }

    img {
      margin-right: rem(4);
    }
  }
}