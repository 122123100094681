html{
  font-size: 100%;
  box-sizing: border-box;
}

*, *::before, *::after{
  box-sizing: inherit;
}

body{
  margin: 0;
  padding: 0;
  font-family: var(--font-inter);
  background: var(--background);
  color: var(--dark-text2);
}

.visually-hidden{
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}